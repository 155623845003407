import axios from 'axios'
// import store from '@/store'
// import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
// const ACCESS_TOKEN = 'ACCESS_TOKEN'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 6000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = error => {
    if (error.response) {
        const data = error.response.data
        // 从 localstorage 获取 token
        // const token = store.getters.accessToken //storage.get(ACCESS_TOKEN)
        if (error.response.status === 403) {
            notification.error({
                message: '禁止访问',
                description: data.message
            })
        }
        if (error.response.status === 401) {
            // if (token) {
                notification.error({
                    message: '认证失败',
                    description: '认证授权信息已过期，请重新登录！'
                })
                // storage.remove(ACCESS_TOKEN)
                // store.dispatch('Logout').then(() => {
                //     setTimeout(() => {
                //         window.location.reload()
                //     }, 1500)
                // })
            // }
        }
        console.log('ererere', error.response)
        return Promise.reject(error.response.data)
    }
    return Promise.reject({
        error_code: 1000000,
        error_message: 'UnKnown Error!',
        message: '未知错误！',
        status: -1
    })
}

const deleteEmptyArray = data => {
    for (let key in data) {
        let v = data[key]
        if (v instanceof Array) {
            if (v.length === 0) {
                // console.log('------deleted------', key, v)
                delete data[key]
            } else data[key].map(m => deleteEmptyArray(m))
        } else if (v instanceof Object) {
            deleteEmptyArray(data[key])
        }
    }
    return data
}

// request interceptor
request.interceptors.request.use(config => {
    // const token = store.getters.accessToken //storage.get(ACCESS_TOKEN)
    config.headers['SCENERY'] = 22
    const token = ''
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use(response => {
    if (response.data.code === 1) return deleteEmptyArray(response.data.data)
    else if (response.data.code === 0) {
        return response.data.data
    } else if (!response.data.code && response.status === 200) return deleteEmptyArray(response.data)
    else return errorHandler({ response })
}, errorHandler)

const installer = {
    vm: {},
    install(Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export { installer as VueAxios, request as axios }
