import request from '../utils/request'
/* 新增预订 */
export function createGwBookingBooking(vo) {
    return request({
        url: 'gw/booking/booking',
        method: 'post',
        data: vo,
    })
}
/* 取消订单 */
export function queryGwBookingCancelOrder(parameter) {
    return request({
        url: 'gw/booking/cancelOrder',
        method: 'get',
        params: parameter,
    })
}
/* 预约配置 */
export function queryGwBookingConfig(parameter) {
    return request({
        url: 'gw/booking/config',
        method: 'get',
        params: parameter,
    })
}
/* 订单详情 */
export function queryGwBookingRecordDetail(parameter) {
    return request({
        url: 'gw/booking/recordDetail',
        method: 'get',
        params: parameter,
    })
}
/* 用户订单列表 */
export function queryGwBookingRecordList(parameter) {
    return request({
        url: 'gw/booking/recordList',
        method: 'get',
        params: parameter,
    })
}
/* 关于我们 */
export function getGwCompany() {
    return request({
        url: 'gw/company',
        method: 'get',
    })
}
/* 娱乐详情 */
export function queryGwGuideEntertainmentDetail(parameter) {
    return request({
        url: 'gw/guide/entertainmentDetail',
        method: 'get',
        params: parameter,
    })
}
/* 娱乐列表 */
export function queryGwGuideEntertainmentPage(parameter) {
    return request({
        url: 'gw/guide/entertainmentPage',
        method: 'get',
        params: parameter,
    })
}
/* 美食详情 */
export function queryGwGuideGoodsDetail(parameter) {
    return request({
        url: 'gw/guide/goodsDetail',
        method: 'get',
        params: parameter,
    })
}
/* 美食列表 */
export function queryGwGuideGoodsPage(parameter) {
    return request({
        url: 'gw/guide/goodsPage',
        method: 'get',
        params: parameter,
    })
}
/* 酒店详情 */
export function queryGwGuideHotelDetail(parameter) {
    return request({
        url: 'gw/guide/hotelDetail',
        method: 'get',
        params: parameter,
    })
}
/* 酒店列表 */
export function queryGwGuideHotelPage(parameter) {
    return request({
        url: 'gw/guide/hotelPage',
        method: 'get',
        params: parameter,
    })
}
/* 特产详情 */
export function queryGwGuideSpecialtyDetail(parameter) {
    return request({
        url: 'gw/guide/specialtyDetail',
        method: 'get',
        params: parameter,
    })
}
/* 特产列表 */
export function queryGwGuideSpecialtyPage(parameter) {
    return request({
        url: 'gw/guide/specialtyPage',
        method: 'get',
        params: parameter,
    })
}
/* 景点详情 */
export function queryGwGuideSpotDetail(parameter) {
    return request({
        url: 'gw/guide/spotDetail',
        method: 'get',
        params: parameter,
    })
}
/* 景点列表 */
export function queryGwGuideSpotPage(parameter) {
    return request({
        url: 'gw/guide/spotPage',
        method: 'get',
        params: parameter,
    })
}
/* 获取首页数据 */
export function getGwIndex() {
    return request({
        url: 'gw/index',
        method: 'get',
    })
}
/* 获取语言解说列表 */
export function getGwIndexAudioList() {
    return request({
        url: 'gw/index/audioList',
        method: 'get',
    })
}
/* 所有天气 */
export function getGwIndexGetWeather() {
    return request({
        url: 'gw/index/getWeather',
        method: 'get',
    })
}
/* 获取资讯、公告、工作内容详情 */
export function queryGwIndexNewsDetail(parameter) {
    return request({
        url: 'gw/index/newsDetail',
        method: 'get',
        params: parameter,
    })
}
/* 获取资讯、公告、工作内容 */
export function queryGwIndexNewsList(parameter) {
    return request({
        url: 'gw/index/newsList',
        method: 'get',
        params: parameter,
    })
}
/* 获取资讯、公告、工作内容（新闻资讯页面带分页） */
export function queryGwIndexNewsPage(parameter) {
    return request({
        url: 'gw/index/newsPage',
        method: 'get',
        params: parameter,
    })
}
/* 获取景区图片资源 */
export function queryGwIndexPicturePage(parameter) {
    return request({
        url: 'gw/index/picturePage',
        method: 'get',
        params: parameter,
    })
}
/* 获取景区详情 */
export function getGwIndexSceneryDetail() {
    return request({
        url: 'gw/index/sceneryDetail',
        method: 'get',
    })
}
/* 获取景区视频资源 */
export function queryGwIndexVideoPage(parameter) {
    return request({
        url: 'gw/index/videoPage',
        method: 'get',
        params: parameter,
    })
}
/* 产业布局列表 */
export function queryGwIndustry(parameter) {
    return request({
        url: 'gw/industry',
        method: 'get',
        params: parameter,
    })
}
/* 产业详情 */
export function queryGwIndustryDetail(parameter) {
    return request({
        url: 'gw/industry/detail',
        method: 'get',
        params: parameter,
    })
}
/* 产业布局导航 */
export function getGwIndustryNavigation() {
    return request({
        url: 'gw/industryNavigation',
        method: 'get',
    })
}
/* 资讯详情 */
export function queryGwNewsDetail(parameter) {
    return request({
        url: 'gw/newsDetail',
        method: 'get',
        params: parameter,
    })
}
/* 资讯（首页列表）--国企改革、企业党建、人才培训通用 */
export function queryGwNewsList(parameter) {
    return request({
        url: 'gw/newsList',
        method: 'get',
        params: parameter,
    })
}
/* 资讯（分页列表）--国企改革、企业党建、人才培训通用 */
export function queryGwNewsPage(parameter) {
    return request({
        url: 'gw/newsPage',
        method: 'get',
        params: parameter,
    })
}
/* 在线留言 */
export function createGwOnlineMessage(vo) {
    return request({
        url: 'gw/onlineMessage',
        method: 'post',
        data: vo,
    })
}
/* 人才招聘 */
export function getGwRecruitmentList() {
    return request({
        url: 'gw/recruitmentList',
        method: 'get',
    })
}
/* 新增投诉 */
export function createGwServiceAddComplaint(vo) {
    return request({
        url: 'gw/service/addComplaint',
        method: 'post',
        data: vo,
    })
}
/* 新增咨询 */
export function createGwServiceAddOpinion(vo) {
    return request({
        url: 'gw/service/addOpinion',
        method: 'post',
        data: vo,
    })
}
/* 人才理念 */
export function getGwTalentConcept() {
    return request({
        url: 'gw/talentConcept',
        method: 'get',
    })
}
/* 用户投诉列表 */
export function queryGwUserComplaintList(parameter) {
    return request({
        url: 'gw/user/complaintList',
        method: 'get',
        params: parameter,
    })
}
/* 获取验证码图片 */
export function getGwUserGetCaptcha() {
    return request({
        url: 'gw/user/getCaptcha',
        method: 'get',
    })
}
/* 用户登录 */
export function createGwUserLogin(loginVo) {
    return request({
        url: 'gw/user/login',
        method: 'post',
        data: loginVo,
    })
}
/* 退出登录 */
export function createGwUserLogout() {
    return request({
        url: 'gw/user/logout',
        method: 'post',
    })
}
/* 用户咨询列表 */
export function queryGwUserOpinionList(parameter) {
    return request({
        url: 'gw/user/opinionList',
        method: 'get',
        params: parameter,
    })
}
/* 用户注册 */
export function createGwUserRegister(registerVo) {
    return request({
        url: 'gw/user/register',
        method: 'post',
        data: registerVo,
    })
}
/* 重置密码 */
export function createGwUserResetPassword(vo) {
    return request({
        url: 'gw/user/resetPassword',
        method: 'post',
        data: vo,
    })
}
/* 找回密码 */
export function createGwUserRetrievePassword(vo) {
    return request({
        url: 'gw/user/retrievePassword',
        method: 'post',
        data: vo,
    })
}
/* 发送短信验证码 */
export function queryGwUserSendSmsCode(parameter) {
    return request({
        url: 'gw/user/sendSmsCode',
        method: 'get',
        params: parameter,
    })
}
/* 更新用户信息 */
export function createGwUserUpdateUserInfo(info) {
    return request({
        url: 'gw/user/updateUserInfo',
        method: 'post',
        data: info,
    })
}
/* 微信JS_SDK签名 */
export function queryGwUserWechartSignature(parameter) {
    return request({
        url: 'gw/user/wechartSignature',
        method: 'get',
        params: parameter,
    })
}
/* 核销人员登录 */
export function createGwUserWriteLogin(loginVo) {
    return request({
        url: 'gw/user/writeLogin',
        method: 'post',
        data: loginVo,
    })
}
/* 核销 */
export function queryGwWriteOffDoWrite(parameter) {
    return request({
        url: 'gw/writeOff/doWrite',
        method: 'get',
        params: parameter,
    })
}
/* 核销记录列表 */
export function queryGwWriteOffWriteOffList(parameter) {
    return request({
        url: 'gw/writeOff/writeOffList',
        method: 'get',
        params: parameter,
    })
}
/* 获取首页张爱萍相关数据 */
export function getGwZap() {
    return request({
        url: 'gw/zap',
        method: 'get',
    })
}
/* 张爱萍生平介绍详情 */
export function queryGwZapIntroduceDetail(parameter) {
    return request({
        url: 'gw/zap/introduceDetail',
        method: 'get',
        params: parameter,
    })
}
/* 张爱萍生平故事详情 */
export function queryGwZapStoryDetail(parameter) {
    return request({
        url: 'gw/zap/storyDetail',
        method: 'get',
        params: parameter,
    })
}
/* 故事列表 */
export function queryGwZapStoryPage(parameter) {
    return request({
        url: 'gw/zap/storyPage',
        method: 'get',
        params: parameter,
    })
}
