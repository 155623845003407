<template>
    <div class="AboutUs">
        <!-- <bread-link></bread-link> -->
        <div class="banner1">
            <div class="title-box">
                <div class="ch-text">产业布局</div>
            </div>
        </div>
        <div class="warpper">
            <div class="menu-box">
                <ul class="menu">
                    <li :class="activeCode == item.code ? 'active' : ''" v-for="(item, index) in infoData" :key="index" @click="getListByCode(item.code)">{{ item.name }}</li>
                </ul>
            </div>
            <div class="list" v-for="(item, index) in dataList" :key="index">
                <div class="img-box" v-if="index % 2 != 0">
                    <img width="400" height="400" :src="item.img" alt="" />
                    <span class="deep-img" :style="{ background: 'url(' + item.logo + ') 50% 50%  /contain no-repeat' }"></span>
                </div>
                <div class="main">
                    <div class="title">{{ item.name }}</div>
                    <pre class="desc" :title="item.introduction" v-html="common.splitStr(item.introduction, 200)"></pre>

                    <div class="btn-box">
                        <span class="more" @click="readDetails('Industry', item.id, item.url)">查看详情</span>
                    </div>
                </div>
                <div class="img-box" v-if="index % 2 == 0">
                    <img width="400" height="400" :src="item.img" alt="" />
                    <span class="deep-img right" :style="{ background: 'url(' + item.logo + ') 50% 50%  /contain no-repeat' }"></span>
                </div>
            </div>
            <a-spin class="loading" size="large" v-if="loading" />
        </div>
        <div id="right-box" v-if="false">
            <div class="box">
                <span class="icon"></span>
                <span class="text">石海景区官网</span>
            </div>
            <div class="box">
                <span class="icon"></span>
                <span class="text">僰王山景区官网</span>
            </div>
            <div class="box top" v-if="showScrollTop">
                <span class="icon"></span>
                <span class="text">回到顶部</span>
            </div>
        </div>
    </div>
</template>

<script>
//页面引入swiper
import { getGwIndustryNavigation, queryGwIndustry } from '@/api/basic'
export default {
    name: 'Industry',
    data() {
        return {
            infoData: [],
            dataList: [],
            activeCode: '',
            loading: false,
            showScrollTop: false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.rightBox)
        this.init()
    },
    destroyed() {
        window.removeEventListener('scroll', this.rightBox)
    },
    methods: {
        init() {
            getGwIndustryNavigation().then(res => {
                this.infoData = res.data
                let code = this.$route.params.code
                if (code) {
                    this.activeCode = code
                } else {
                    this.activeCode = this.infoData[0].code
                }
                this.getListByCode(this.activeCode)
            })
        },
        getListByCode(code) {
            let params = {
                code: code
            }
            this.loading = true
            this.activeCode = code
            queryGwIndustry(params).then(res => {
                this.dataList = res.data
                this.loading = false
            })
        },
        readDetails(type, id, url) {
            if (url) {
                window.location.href = url
            } else {
                this.$router.push({ name: 'Details', params: { type: type, id: id } })
            }
        },
        rightBox() {
            let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
            if (scrollTop > 400) {
                this.showScrollTop = true
            } else {
                this.showScrollTop = false
            }
        }
    }
}
</script>
<style lang="less" scoped>
.AboutUs {
    background: #fff;
    .banner1 {
        width: 100%;
        height: 680px;
        background: url(../../../assets/images/news/bg.png) 50% 50% no-repeat;
        position: relative;
        .title-box {
            width: 1300px;
            text-align: center;
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, 50%);
            color: #fff;
            .en-text {
                font-size: 60px;
            }
            .ch-text {
                font-size: 50px;
                margin-top: 40px;
                position: relative;
                padding-bottom: 20px;
                position: relative;
                font-weight: bold;
            }
            .ch-text:before {
                content: '';
                width: 40px;
                height: 5px;
                background: #fff;
                position: absolute;
                bottom: 0;
                left: 50%;
                margin-left: -20px;
            }
        }
    }
    .warpper {
        width: 1300px;
        margin: 0 auto;
        margin-top: 100px;
        padding: 0 0 50px 0;
        position: relative;
        min-height: 200px;
    }
    .menu-box {
        display: flex;
        .menu {
            width: 430px;
            background: #fff;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            li {
                width: 130px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                color: #333;
                box-sizing: border-box;
                cursor: pointer;
                border: 1px solid #e8e8e8;
            }
            li.active {
                background: #ff721d;
                color: #fff;
            }
        }
    }
    .list {
        width: 100%;
        padding: 30px 60px;
        margin-top: 80px;
        background: #fff;
        display: flex;
        justify-content: space-between;
        position: relative;
        .img-box {
            width: 400px;
            height: 400px;
            position: relative;
            img {
                position: relative;
                z-index: 2;
            }
            .deep-img {
                width: 200px;
                height: 200px;
                position: absolute;
                display: block;
                bottom: -45px;
                left: -50px;
                opacity: 0.5;
                z-index: 1;
            }
            .deep-img-right {
                position: absolute;
                bottom: -45px;
                right: -50px;
                opacity: 0.5;
                z-index: 1;
            }
        }
        .main {
            width: 100%;
            padding: 0 60px;
            .title {
                font-size: 28px;
                font-weight: 400;
                color: #333333;
                padding-top: 40px;
                position: relative;
            }
            .title:before {
                content: '';
                width: 36px;
                height: 4px;
                background: #ff721d;
                position: absolute;
                top: 0;
                left: 0;
            }
            .desc {
                margin-top: 30px;
                white-space: pre-wrap;
                word-break: break-all;
            }
            .btn-box {
                .more {
                    display: inline-block;
                    margin-top: 40px;
                    color: #999999;
                    border: 1px solid #999999;
                    font-size: 14px;
                    padding: 5px 10px;
                    cursor: pointer;
                    border-radius: 3px;
                }
                .more:hover {
                    color: #ff721d;
                    border-color: #ff721d;
                }
            }
        }
    }
    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
#right-box {
    width: 130px;
    background: #ffffff;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.07);
    padding: 10px 15px;
    font-size: 14px;
    z-index: 99;
    .box {
        text-align: center;
        border-bottom: 1px solid #e8e8e8;
        padding: 20px 0;
        cursor: pointer;
        .icon {
            width: 31px;
            height: 30px;
            display: block;
            margin: 0 auto;
            background: url('../../../assets/images/icon/word-icon.png') no-repeat;
            background-size: cover;
        }
        .text {
            display: block;
            margin-top: 10px;
        }
    }
    .box:last-of-type {
        border: none;
    }
    .top {
        .icon {
            width: 25px;
            height: 12px;
            background: url('../../../assets/images/icon/top-icon.png') no-repeat;
            background-size: cover;
        }
    }
}
</style>
